import * as React from "react"

export const Accent = ({children, wrap=false}) => {
    return (
        <span className={"text-accent2" + (wrap?"": " whitespace-nowrap")}>{children}</span>
    )
}

export const Accent2 = ({children}) => {
    return (
        <span className={"text-accent whitespace-nowrap"}>{children}</span>
    )
}
export const AccentKatana = ({children}) => {
    return (
        <span className={"text-katana whitespace-nowrap"}>{children}</span>
    )
}
export const Together = ({children}) => {
    return (
        <span className={"whitespace-nowrap"}>{children}</span>
    )
}
export const Sentence = ({children}) => {
    return (
        <span className={"block my-4 lg:my-0 lg:inline md:container"}>{children}</span>
    )
}

export const ScreenSizeHelper = () => {
    return (
        <div className={"absolute top-6 left-6"}>
            <p className={"block 3xs:hidden"}>SuperSmall</p>
            <p className={"hidden 3xs:block 2xs:hidden"}>3X-Small</p>
            <p className={"hidden 2xs:block xs:hidden"}>XX-Small</p>
            <p className={"hidden xs:block sm:hidden"}>X-Small</p>
            <p className={"hidden sm:block md:hidden"}>Small</p>
            <p className={"hidden md:block lg:hidden"}>Medium</p>
            <p className={"hidden lg:block xl:hidden"}>Large</p>
            <p className={"hidden xl:block 2xl:hidden"}>X-Large</p>
            <p className={"hidden 2xl:block 3xl:hidden"}>XX-Large</p>
            <p className={"hidden 3xl:block 4xl:hidden"}>3X-Large</p>
            <p className={"hidden 4xl:block 5xl:hidden"}>4X-Large</p>
            <p className={"hidden 5xl:block"}>5X-Large</p>
        </div>
    )
}
