import * as React from 'react';
import {useState, useCallback} from 'react';

const ThankYou = ({error, success}) => {
    if(!error && !success) {
        return <div className="flex flex-col items-center justify-center h-screen">
            <div className="text-center">
                <h2 className="text-3xl font-bold">Your form is being submitted...</h2>
                <p className="text-lg">Thank you for contacting us.</p>
            </div>
        </div>
    }
    if(error) {
        return <div className="flex flex-col items-center justify-center h-screen">
            <div className="text-center">
                <h2 className="text-3xl font-bold">There was an error</h2>
                <p className="text-lg">Please, try again later or contact us through email <a href={"mailto:contact@gatsbyninja.com"}>contact@gatsbyninja.com</a></p>
            </div>
        </div>
    }
    return <div className="flex flex-col items-center justify-center h-screen">
        <div className="text-center">
            <h2 className="text-3xl font-bold">Thank you for contacting us!</h2>
            <p className="text-lg">We will get back to you as soon as possible.</p>
        </div>
    </div>
}

const ContactForm = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [isSent, setIsSent] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [formErrors, setFormErrors] = useState({});

    console.log('render');

    const sendDataToHubspot = async (data = {}) => {
        const url = `${process.env.GATSBY_HUBSPOT_URL}/${process.env.GATSBY_HUBSPOT_ID}/${process.env.GATSBY_HUBSPOT_FORM_ID}`
        return fetch(url, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {'Content-Type': 'application/json',},
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify(data),
        })
    }
    const getCookie = (cookieName) => {
        const value = "; " + document.cookie;
        const parts = value.split("; " + cookieName + "=");
        if (parts.length === 2) return parts.pop().split(";").shift();
    }
    const sendForm = useCallback(
        () => {
            const cookie = getCookie('hubspotutk')
            const data = {
                submittedAt: Date.now(),
                fields: [
                    {
                        name: "firstname",
                        value: name,
                    },
                    {
                        name: "email",
                        value: email,
                    },
                    {
                        name: "message",
                        value: message,
                    },
                ],
                context: {
                    hutk: cookie,
                    pageUri: window.location.href,
                    pageName: document.title,
                },
                skipValidation: true,
            }
            sendDataToHubspot(data).then((_r) => {
                setSuccess(true);
            }).catch((_e) => {
                setError(true);
            })
            setIsSent(true)
        },
        [name, email, message, setIsSent]
    );
    const changeName = useCallback((e) => setName(e.target.value), []);
    const changeEmail = useCallback((e) => setEmail(e.target.value), []);
    const changeMessage = useCallback((e) => setMessage(e.target.value), []);
    const validate = useCallback((e) => {
        const validateName = () => {
            if (name.length > 0) {
                setFormErrors(f => { const _f = {...f}; delete _f.name; return _f; })
                return true
            } else {
                setFormErrors(f => ({...f, name: 'Name is required'}))
                return false
            }
        }
        const validateEmail = () => {
            if (email.length > 0 && email.includes('@')) {
                setFormErrors(f => { const _f = {...f}; delete _f.email; return _f; })
                return true
            } else {
                setFormErrors(f => ({...f, email:'Email is required'}))
                return false
            }
        }
        const validateMessage = () => {
            if (message.length > 0) {
                setFormErrors(f => { const _f = {...f}; delete _f.message; return _f; })
                return true
            } else {
                setFormErrors(f => ({...f, message: 'Message is required'}))
                return false
            }
        }
        switch (e?.target?.name) {
            case 'name':
                return validateName()
            case 'email':
                return validateEmail()
            case 'message':
                return validateMessage()
            default:
                const n = validateName()
                const e = validateEmail()
                const m = validateMessage()
                return n && e && m;
        }
    }, [name, email, message, setFormErrors]);
    const handleSubmit = useCallback((e) => {
        e.preventDefault();
        if(validate(null)) {
            sendForm();
        }
    }, [validate, sendForm]);
    const hasError = useCallback((field) => {
        return !!formErrors[field];
    }, [formErrors]);
    const errorStyle = useCallback((field) => {
        return hasError(field) ? ' border-red-500 border' : '';
    }, [hasError]);

    const formHasErrors = useCallback(() => {
        return Object.keys(formErrors).length > 0;
    }, [formErrors]);

    // return isSent ?
    //     :
    if(isSent) {
        return <ThankYou error={error} success={success} />
    }
    return <form onSubmit={handleSubmit}
                 className={"py-20 flex flex-col lg:grid lg:grid-cols-2 lg:space-y-0 lg:gap-10 space-y-10"}>
        <div className={"w-full lg:col-span-2 overflow-hidden transition-all duration-500 ease-out " + (formHasErrors()?  'max-h-[10em]': 'max-h-0')}>
            <div
                className={"text-xl p-4 bg-red-800 items-center text-indigo-100 leading-none"}
                role="alert"><ul className="px-4 list-disc">
                {/*<span class="flex rounded-full bg-red-500 uppercase px-2 py-1 text-xs font-bold mr-3">ERROR</span>*/}
                {Object.keys(formErrors).map((key) => <li className="py-2 font-semibold flex-grow-0 mr-2 text-left flex-auto"
                                                            key={key}>{formErrors[key]}</li>)}
                </ul>
            </div>
        </div>
        <input type={"text"} onChange={changeName} onBlur={validate} name={"name"} placeholder={"Name"} value={name}
               className={"w-full bg-input text-input-text p-4 " + errorStyle("name")}/>
        <input type={"text"} onChange={changeEmail} onBlur={validate} name={"email"} placeholder={"Email"} value={email}
               className={"w-full bg-input text-input-text p-4" + errorStyle("email")}/>
        <textarea onChange={changeMessage} onBlur={validate} placeholder={"Message"} name={"message"}
                  rows={10}
                  value={message}
                  className={"w-full h-80 bg-input text-input-text p-4 col-span-2" + errorStyle("message")}/>
        <button className={"bg-accent font-black text-xl xs:text-2xl px-10 py-5 my-16 lg:col-span-2"}>
            Contact Us
        </button>
    </form>
}

export default ContactForm