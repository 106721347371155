import * as React from "react"
import Layout from "../components/Layout";
import HeroImage from "../icons/HeroImage";
import Seo from "../components/Seo";
import {IconBetterSecurity, IconDecentralized, IconFocusedOnUsers, IconQuickDevelopment} from "../icons/Icons";
import ContactForm from "../components/ContactForm";
import {Sentence, Together, Accent, Accent2} from "../components/StyledComponents";
import YouTube from "../components/YouTube";

const data = {
    canonical: '/',
    title: 'GatsbyNinja'
}



const IndexPage = () => {
    return (
        <Layout breadcrumbs={true} breadcrumbsJustMeta={true} canonical={data.canonical}
                title={data.title}>
            <Seo title={"GatsbyNinja: Blazing-fast, secured and SEO-optimized websites"} lang={"en-US"} description={""} canonical={'https://gatsbyninja.com/'}
                 />
            {/*<ScreenSizeHelper />*/}
            <section className={"px-6 py-16 max-container"}>
                <div className={"relative w-full lg:mt-36"}>
                    <div
                        className={"absolute -right-20 -top-12 w-3/4 lg:w-1/2 lg:max-h-[600px] lg:top-[-150px] max-h-full aspect-square -z-10 text-center"}>
                        <HeroImage className={"h-full w-full"}/>
                    </div>
                    <h1 className={"text-var-8xl block font-black mb-12 lg:w-1/2"}>Who <br
                        className={"lg:hidden"}/>we&nbsp;are</h1>
                    <p className={"text-2xl xs:text-3xl my-4 col-start-1 row-start-2 lg:w-1/2 lg:text-2xl"}>
                        <Sentence>We are a&nbsp;team of&nbsp;passionate Ninja engineers specializing in&nbsp;web development.{' '}</Sentence>
                        <Sentence>Our team relies on&nbsp;results and just like the websites we build, we work fast ⚡ ️and reliably 💪.</Sentence>
                    </p>
                </div>

                <div className={"flex 2xs:flex-row flex-col"}>
                    <a href={"#contact"} className={"bg-accent font-black text-xl xs:text-2xl px-10 py-5 my-16"}>
                        Talk to a Ninja
                    </a>
                    <a href={"#contact"}  className={"underline block text-link ml-10 text-xl xs:text-2xl place-self-center"}>Contact Us</a>
                </div>
            </section>
            <section className={"px-6 py-16 bg-alt-background h-screen md:h-auto"}>
                <div className={"max-container"}>
                    <h2 className={"text-var-7xl text-left lg:text-center font-black mb-12 "} id={"what"}>What we&nbsp;do</h2>
                    <h3 className={"text-var-4xl font-black text-left lg:text-center"}>We create <Accent>high-performance</Accent>, <Accent>tightly
                        secured</Accent>,<br className={"hidden xl:block"} /> <Together>and <Accent>SEO-optimized</Accent></Together> <Together>Jamstack websites</Together>.</h3>
                    <p className={"xs:text-3xl 2xs:text-2xl text-xl pt-20 text-left lg:text-center"}>
                        Our team thoughtfully 🧠 listens to Google developers <br className={"hidden lg:block"} />
                        to deliver websites that best correspond with search <br className={"hidden lg:block"} />
                        engine signals as <Accent2>Core Web Vitals</Accent2> 🚀.</p>
                </div>
            </section>
            <section className={"px-6 py-16 max-container"}>
                <h2 className={"text-var-7xl text-left lg:text-center font-black mb-12 "} id={"why"}>Why GatsbyJS?</h2>

                <div className={"flex flex-col lg:grid lg:grid-cols-2 lg:grid-rows-2 lg:gap-10"}>
                    <div className={""}>
                        <h3 className={"text-var-3xl font-black mb-3"}>
                            <div className={"text-accent2 py-4"}><IconQuickDevelopment className={"w-20"} /></div>
                            <Accent>Quick development,</Accent>{" "}<br className={"hidden lg:block"} />
                            <Together>No trade-offs</Together></h3>
                        <p className={"xs:text-2xl 2xs:text-2xl text-xl pt-4 text-left"}>
                            <Sentence>GatsbyJS is one of the leading Jamstack projects that are <Together>fast
                                ⚡</Together>️ <Together>and flexible 🐍.</Together></Sentence>{" "}
                            <Sentence>It can use a wide range of APIs and CMSes giving a good time to <Together>market
                                🚀</Together> <Together>without trade-offs!</Together></Sentence>
                        </p>
                    </div>


                    <div className={""}>
                        <h3 className={"text-var-3xl mb-3 font-black"}>
                            <div className={"text-accent2 py-4"}><IconFocusedOnUsers className={"w-20"} /></div>
                            <Accent>Focused on Users,</Accent>{" "}<br className={"hidden lg:block"} />
                            <Together>Finest for SEO</Together></h3>
                        <p className={"xs:text-2xl 2xs:text-2xl text-xl pt-4 text-left"}>
                            <Sentence><Together>Blazing-fast 🚀 </Together>and <Together>reliable 💪</Together> website
                                focused on user experience is a key factor
                                resulting in better conversion rates and higher search engine ratings.</Sentence>
                        </p>
                    </div>

                    <div className={""}>
                        <h3 className={"text-var-3xl font-black mb-4"}>
                            <div className={"text-accent2 py-4"}><IconDecentralized className={"w-20"} /></div>
                            <Accent wrap={true}>Decentralized architecture,</Accent>{" "}<br className={"hidden lg:block"} />
                            <Together>Resilient solution</Together></h3>
                        <p className={"xs:text-2xl 2xs:text-2xl text-xl pt-4 text-left"}>
                            <Sentence>CDN deployment makes the website live on the edge 📡 distributed all across the
                                internet 🌐, just where your users are.</Sentence>{" "}
                            <Sentence>Thanks to decentralization, it is <Together>fast ⚡️</Together>, <Together>reliable
                                💪</Together> and resilient.</Sentence>
                        </p>
                    </div>


                    <div className={""}>
                        <h3 className={"text-var-3xl mb-4 font-black"}>
                            <div className={"text-accent2 py-4"}><IconBetterSecurity className={"w-20"} /></div>
                            <Accent wrap={true}>Better Security,</Accent>{" "}<br className={"hidden lg:block"} />
                            <Together>Easier Scaling</Together></h3>
                        <p className={"xs:text-2xl mb-4 2xs:text-2xl text-xl pt-4 text-left"}>
                            <Sentence>Jamstack, by nature, is all about decupling a frontend from a content
                                management.</Sentence>{" "}
                            <Sentence>Together with Gatsby it gives easy scalability 🔥 and the best security
                                🔒.</Sentence>
                        </p>
                    </div>
                </div>
            </section>

            <section className={"w-full h-full aspect-video"}>
                <YouTube
                     src={"https://www.youtube.com/embed/S_tvd4bylPk"}
                     title={"Welcome to Gatsby Ninja"}
                     className={"w-full h-full aspect-video"} />
            </section>

            <section className={"px-6 py-16 max-container"} id={"contact"}>
                <h2 className={"text-var-7xl text-left lg:text-center font-black mb-8"}>Get <Together>in touch</Together></h2>
                <h3 className={"text-var-4xl font-black text-accent lg:text-center"}>Let Us Work {" "}<br className={"block lg:hidden"} /> For You</h3>
                <ContactForm />
            </section>
        </Layout>
    )
}

export default IndexPage